import React from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton
} from 'react-accessible-accordion'
import { FormattedMessage } from 'react-intl'
import {
  isMobile
} from 'react-device-detect'
import {
  documentToReactComponents,
  documentToReactSidebarComponentsOptions
} from '../components/contentful'
import checkListIcon from '../images/icon-checklist.svg'

class ChapterSidebarItem extends React.Component {
  constructor (props) {
    super(props)

    let expands = ['things_you_can_do_' + this.props.uuid]
    if (isMobile) {
      expands = []
    }
    this.state = {
      expands
    }
  }

  render () {
    const content = documentToReactComponents(
      this.props.document,
      documentToReactSidebarComponentsOptions(this.props.locale)
    )
    const { expands } = this.state

    return (
      <div className='mb-6 lg:mb-10 break-words px-0'>
        <Accordion allowZeroExpanded onChange={e => this.setState({ expands: e })} preExpanded={expands} className='bg-gray'>
          <AccordionItem uuid={'things_you_can_do_' + this.props.uuid} className='bg-gray'>
            <AccordionItemHeading aria-level='2'>
              <AccordionItemButton className='bg-turqouise'>
                <div className={`h-16 px-3 flex justify-between items-center text-2xl text-white font-bold${expands.length !== 0 ? ' bg-turqouise' : ' bg-coral'}`}>
                  <div className='flex items-center bg-transparent mb-0'>
                    <img src={checkListIcon} alt='' className='inline-block w-10 h-10 mr-3 ie-object-contain' width='40' height='40' />
                    <h1 className='text-white text-2xl font-bold p-2'><FormattedMessage id='things-can-do-title' /></h1>
                  </div>
                  <span className='mr-3'>{expands.length === 0 ? '〉' : '﹀'}</span>
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className='p-6'>
                {content}
              </div>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
    )
  }
}

export default ChapterSidebarItem
