import React from 'react'
import { graphql } from 'gatsby'
import { BLOCKS } from '@contentful/rich-text-types'
import PropTypes from 'prop-types'
// import Img from 'gatsby-image'

import {
  documentToReactComponents,
  documentToReactChapterComponentsOptions
} from '../components/contentful'
import Layout from '../components/layout'
import SEO from '../components/seo'
// import BookChecklist from "../components/book-checklist"
import BookChecklistPreview from '../components/book-checklist-preview'
import ChapterResources from '../components/chapter-resources'
import ChapterSidebarItem from '../components/chapter-sidebar-item'

class ChapterPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      section: null,
      prevChapter: null,
      nextChapter: null
    }

    this.setChapterSection = this.setChapterSection.bind(this)
  }

  setChapterSection (sections) {
    const chapter = this.props.data.contentfulChapters
    const sectionIndex = sections.findIndex(sec => !!sec.chapters.find(ch => ch.slug === chapter.slug))
    if (sections[sectionIndex] !== this.state.section) {
      const chapterIndex = sections[sectionIndex].chapters.findIndex((ch, index) => ch.slug === chapter.slug)
      if (chapterIndex === 0) {
        if (sections[sectionIndex - 1]) {
          this.setState({
            section: sections[sectionIndex],
            prevChapter: sections[sectionIndex - 1].chapters[sections[sectionIndex - 1].chapters.length - 1],
            nextChapter: sections[sectionIndex].chapters[1]
          })
        } else {
          this.setState({
            section: sections[sectionIndex],
            prevChapter: null,
            nextChapter: sections[sectionIndex].chapters[1]
          })
        }
      } else if (chapterIndex === sections[sectionIndex].chapters.length - 1) {
        if (sections[sectionIndex + 1]) {
          this.setState({
            section: sections[sectionIndex],
            prevChapter: sections[sectionIndex].chapters[chapterIndex - 1],
            nextChapter: sections[sectionIndex + 1].chapters[0]
          })
        } else {
          this.setState({
            section: sections[sectionIndex],
            prevChapter: sections[sectionIndex].chapters[chapterIndex - 1],
            nextChapter: null
          })
        }
      } else {
        this.setState({
          section: sections[sectionIndex],
          prevChapter: sections[sectionIndex].chapters[chapterIndex - 1],
          nextChapter: sections[sectionIndex].chapters[chapterIndex + 1]
        })
      }
    }
  }

  render () {
    const chapter = this.props.data.contentfulChapters
    const intro = chapter.intro.intro
    const document = chapter.contents.json
    const checklist = chapter.checklist
    const resources = chapter.resources
    const thingsYouCanDoDocument = chapter.thingsYouCanDo.json

    // @TODO why we don't have intl here, bc we are using createPage here?
    const locale = this.props.pageContext.locale || 'en-US'

    let heroImageUrl = '//images.ctfassets.net/pjx1km6eothd/4O64wdv3fDQk6RF1L1qf34/04505acfa008ba829f987fbdc57813d7/Dad-baby-mom-hero.jpg'
    let heroAltText = 'Mom and dad reading to baby'
    let newDocument = {
      ...document
    }

    // @todo use .shift() here on the array
    if (document.content && document.content[0].nodeType === BLOCKS.EMBEDDED_ASSET) {
      heroImageUrl = document.content[0].data.target.fields ? document.content[0].data.target.fields.file['en-US'].url : heroImageUrl
      // remove "hero" and replace "-" with a space
      heroAltText = document.content[0].data.target.fields ? document.content[0].data.target.fields.title['en-US'].replace(/hero/g, '').replace(/-/g, ' ') : heroAltText

      newDocument = {
        ...document,
        content: document.content.filter((c, index) => index !== 0)
      }
    }

    const chapterReactComponents = documentToReactComponents(
      newDocument,
      documentToReactChapterComponentsOptions(locale)
    )

    return (
      <Layout
        location={this.props.location}
        heroUrl={heroImageUrl}
        heroAltText={heroAltText}
        locale={this.props.pageContext.locale}
        setSections={this.setChapterSection}
      >
        <SEO title={chapter.title} />
        <div className='container mx-auto flex flex-no-wrap overflow-hidden mb-12 -mt-8 flex-col lg:flex-row chapter relative'>
          <div className='overflow-hidden w-full lg:w-2/3 mr-12 px-6 '>
            <header>
              <div className='h-16 px-3 flex items-center text-2xl text-white font-bold bg-turqouise'>
                <img src={this.state.section ? this.state.section.icon.file.url : ''} alt='' className='inline-block w-10 h-10 mr-3 ie-object-contain' width='40' height='40' />
                <h1 className='text-white text-2xl font-bold'>{this.state.section ? this.state.section.title : 'Section'}</h1>
              </div>
              <h1 className='-ml-1 text-turqouise text-4xl sm:text-6xl font-bold px-0 py-3 sm:py-6 leading-none'>{chapter.title}</h1>
              <p className='py-6 pt-0 sm:pt-6 lg:py-0 lg:my-2'>{
                intro.split('\n').map((str, id) => <span className='text-xl sm:text-2xl leading-tight' key={id}>{str}<br /></span>)
              }
              </p>
              {/* {chapterHeroImage && (
                <Img fluid={chapterHeroImage.localFile.childImageSharp.fluid} />
              )} */}
            </header>
            <div className='w-full overflow-hidden w-full lg:w-1/3 lg:w-1/3 xl:w-1/3 block lg:hidden'>
              {/* {chapterImages.map(image => (
                <Img
                  fluid={image.localFile.childImageSharp.fluid}
                  key={image.id}
                />
              ))} */}
              <ChapterResources resources={resources} uuid='inline' />
              <ChapterSidebarItem document={thingsYouCanDoDocument} locale={this.props.pageContext.locale} uuid='inline' />
              {/* // @TODO create a preview of the checklist and turn into a modal */}
              {checklist &&
                <BookChecklistPreview checklist={checklist} uuid='inline' />}
              {/* <BookChecklist checklist={checklist} /> */}
            </div>
            {chapterReactComponents}
          </div>
          <div className='w-full overflow-hidden lg:w-1/3 lg:w-1/3 xl:w-1/3 hidden lg:block'>
            {/* {chapterImages.map(image => (
              <Img
                fluid={image.localFile.childImageSharp.fluid}
                key={image.id}
              />
            ))} */}
            <ChapterResources resources={resources} uuid='sidebar' />
            <ChapterSidebarItem document={thingsYouCanDoDocument} locale={this.props.pageContext.locale} uuid='sidebar' />
            {/* // @TODO create a preview of the checklist and turn into a modal */}
            {checklist &&
              <BookChecklistPreview checklist={checklist} uuid='sidebar' />}
            {/* <BookChecklist checklist={checklist} /> */}
          </div>
        </div>
      </Layout>
    )
  }
}

ChapterPage.contextTypes = {
  intl: PropTypes.object
}

export default ChapterPage

export const chapterQuery = graphql`
  query chapterQuery($id: String!) {
    contentfulChapters(id: { eq: $id }) {
      id
      title
      slug
      intro {
        intro
      }
      contents {
        json
      }
      thingsYouCanDo {
        json
      }
      resources {
        id
        name
        description {
          id
          description
        }
        category {
          id
          name
          node_locale
        }
        phoneNumbers
        websiteSupportsSpanish
        website
        websiteSupportsSpanish
      }
      checklist {
        id
        title
        description {
          id
          description
        }
        items {
          id
          item
          type
        }
      }
    }
  }
`
