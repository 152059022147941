import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton
} from 'react-accessible-accordion'
import { FormattedMessage } from 'react-intl'
import {
  isMobile
} from 'react-device-detect'
import searchIcon from '../images/icon-find-help.png'

class ChapterResources extends React.Component {
  constructor (props) {
    super(props)

    let expands = ['accordion_item_find_help' + this.props.uuid]
    if (isMobile) {
      expands = []
    }
    this.state = {
      expands
    }
  }

  render () {
    const { expands } = this.state
    const locale = this.context.intl.locale || 'en-US'
    const resourcesLink = '/' + locale + '/resources'

    return (
      <div className='mb-6 lg:mb-10 break-words px-0'>
        <Accordion allowZeroExpanded onChange={e => this.setState({ expands: e })} preExpanded={expands} className='bg-gray'>
          <AccordionItem uuid={'accordion_item_find_help' + this.props.uuid}>
            <AccordionItemHeading aria-level='2'>
              <AccordionItemButton className='bg-turqouise'>
                <div className={`h-16 px-3 flex justify-between items-center text-2xl text-white font-bold${expands.length !== 0 ? ' bg-turqouise' : ' bg-coral'}`}>
                  <div className='flex items-center bg-transparent mb-0'>
                    <img src={searchIcon} alt='' className='inline-block w-10 h-10 mr-3 ie-object-contain' width='40' height='40' />
                    <h1 className='bg-transparent text-white text-2xl font-bold p-2'><FormattedMessage id='where-to-find-title' /></h1>
                  </div>
                  <span className='mr-3'>{expands.length === 0 ? '〉' : '﹀'}</span>
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              {this.props.resources && this.props.resources.map((resource, key) => (
                <div key={resource.id + key} className=''>
                  <div className='group pl-8 pr-8 py-4 hover:bg-coral hover:-ml-4 hover:pl-4 mb-2 lg:mb-0 leading-tight'>
                    <a
                      href={resource.website}
                      target='_blank'
                      rel='noopener noreferrer'
                      className='w-full font-bold text-coral group-hover:text-white'
                    >
                      {resource.name}
                    </a>
                    <br />
                    <span className='text-black group-hover:text-white'>{resource.description.description}</span>
                    <br />
                    {resource.phoneNumbers &&
                      <a
                        href={'tel:' + resource.phoneNumbers}
                        className='mt-1 block text-black group-hover:text-white'
                      >
                        {resource.phoneNumbers}
                      </a>}
                  </div>
                  <hr className='my-0 border-white border border-solid' />
                </div>
              ))}
              <p className='group pl-8 pr-8 pb-4 pt-4 text-coral font-bold hover:bg-coral hover:text-white'><Link to={resourcesLink} className='group-hover:text-white'><FormattedMessage id='more-resources-link' /></Link></p>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
    )
  }
}

// Make `this.context.intl` available.
ChapterResources.contextTypes = {
  intl: PropTypes.object
}

export default ChapterResources
