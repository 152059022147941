import React from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton
} from 'react-accessible-accordion'
import { FormattedMessage } from 'react-intl'
import {
  isMobile
} from 'react-device-detect'
import BookChecklist from '../components/book-checklist'
import checkListIcon from '../images/icon-check-list.svg'

const ReactSwal = withReactContent(Swal)

class BookChecklistPreview extends React.Component {
  constructor (props) {
    super(props)
    // default hide checklist
    let expands = ['checklist_preview_' + this.props.uuid]
    if (isMobile) {
      expands = []
    }

    this.state = {
      showChecklist: false,
      expands
    }
    // bind this to showChecklistModal method so you can access react's this
    this.showChecklistModal = this.showChecklistModal.bind(this)
  }

  showChecklistModal () {
    ReactSwal.fire({
      allowOutsideClick: true,
      allowEscapeKey: true,
      allowEnterKey: false,
      showCancelButton: false,
      showConfirmButton: false,
      html: (
        <BookChecklist checklist={this.props.checklist} onClose={ReactSwal.close} />
      )
    })
      .then(result => {

      })
  }

  render () {
    const checklist = this.props.checklist
    const title = checklist.title
    const description = checklist.description.description
    const { expands } = this.state

    return (
      <div className='px-0'>
        <Accordion allowZeroExpanded onChange={e => this.setState({ expands: e })} preExpanded={expands} className='bg-gray-400'>
          <AccordionItem uuid={'checklist_preview_' + this.props.uuid}>
            <AccordionItemHeading aria-level='2'>
              <AccordionItemButton className='bg-turqouise'>
                <div className={`h-16 px-3 flex justify-between items-center text-2xl text-white font-bold text-white${expands.length !== 0 ? ' bg-turqouise' : ' bg-coral'}`}>
                  <div className='flex items-center bg-transparent mb-0'>
                    <img src={checkListIcon} alt='' width='60px' height='60px' className='mt-2' />
                    <h1 className='text-white text-2xl font-bold p-2'><FormattedMessage id='check-list-title' /></h1>
                  </div>
                  <span className='mr-3'>{expands.length === 0 ? '〉' : '﹀'}</span>
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div
                className='p-2 cursor-pointer mb-4'
                onClick={this.showChecklistModal}
              >
                <h2 className='text-lg font-bold mb-2 text-coral'>{title}</h2>
                {description.length && (
                  <p className='text-sm text-gray-700'>
                    {description}
                  </p>
                )}
              </div>
              {/* @TODO implement a11y modal and portal */}
              {this.state.showChecklist && <BookChecklist checklist={checklist} />}
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
    )
  }
}

export default BookChecklistPreview
